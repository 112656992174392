<template>
  <div class="d-flex flex-column">
    <v-img
      src="https://images.unsplash.com/photo-1547570135-c4d6810a9ada?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      alt=""
      max-height="300"
      height="300"
    ></v-img>
    <v-container fluid>
      <v-card flat color="transparent">
        <v-card-title class="text-h5">Kayıt ol</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                maxlength="30"
                hide-details="auto"
                full-width
                label=""
                placeholder="Adınız ve Soyadınız"
                dense
                v-model="$v.registerForm.name.$model"
                :error-messages="nameErrors()"
              >
                <template #prepend-inner>
                  <v-icon class="mt-1" small left v-text="'fas fa-user'"> </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                hide-details="auto"
                full-width
                label=""
                placeholder="E-posta Adresiniz"
                dense
                v-model="$v.registerForm.eMail.$model"
                :error-messages="mailErrors()"
              >
                <template #prepend-inner>
                  <v-icon class="mt-1" small left v-text="'fas fa-at'"> </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <phone-input
                v-model="$v.registerForm.mobilePhone.$model"
                v-bind:properties="{
                  inputmode: 'numeric',
                  prefix: '+90',
                  dense: true,
                  hideDetails: 'auto',
                  placeholder: 'Telefon numaranız',
                  height: '40',
                  errorMessages: phoneNumberErrors(),
                }"
                v-bind:options="{
                  humanMask: '(###)-###-##-##',
                  machineMask: '###########',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: true,
                  lowerCase: false,
                }"
              ></phone-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                maxlength="31"
                type="password"
                autocomplete="off"
                hide-details="auto"
                full-width
                label=""
                placeholder="Şifreniz"
                dense
                v-model="$v.registerForm.password.$model"
                :error-messages="passwordErrors()"
              >
                <template #prepend-inner>
                  <v-icon class="mt-1" small left v-text="'fas fa-lock'"> </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                color="primary"
                hide-details="auto"
                :error-messages="kvkkApprovedErrors()"
                v-model="$v.registerForm.kvkkApproved.$model"
              >
                <template v-slot:label>
                  <div class="caption">
                    <span class="caption font-weight-bold">
                      {{ company.cmpName }}
                      <a class="primary--text" target="_blank" :href="company.endUserContractFile" @click.stop
                        >KVKK Aydınlatma Metnini</a
                      >
                      okuyup anladığımı, verilerimin işlenmesi, aktarılması ve kullanılmasına bu çerçevede açıkça rıza
                      gösterdiğimi beyan ve kabul ederim.
                    </span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-checkbox color="primary" hide-details="auto" v-model="registerForm.contactApproved">
                <template v-slot:label>
                  <div class="caption">
                    <span class="caption font-weight-bold">
                      {{ company.cmpName }} ürün/hizmet teklifleri için iletişim kurulmasını kabul ediyorum.
                    </span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize rounded-lg" color="primary" block height="45" @click="registerUser">
            Kayıt ol
          </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center align-center">
          <div class="mr-2">Daha önce kayıt olduysanız</div>
          <router-link class="text-capitalize" depressed text :to="{ name: 'login' }"> Giriş yapın </router-link>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { REGISTER } from '@/store/modules/auth.module';
import { validationMixin } from 'vuelidate';
import registerValidations from './registerValidations';
import PhoneInput from '@/components/PhoneInput.vue';

export default {
  name: 'Register',
  mixins: [validationMixin, registerValidations],
  components: {
    PhoneInput,
  },
  data() {
    return {
      registerForm: {
        name: null,
        eMail: null,
        mobilePhone: null,
        password: null,
        kvkkApproved: false,
        contactApproved: false,
      },
    };
  },
  computed: {
    ...mapGetters(['company', 'getSite']),
  },
  methods: {
    ...mapActions({
      register: REGISTER,
    }),
    registerUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const payload = {
        ...this.registerForm,
        mobilePhone: `90${this.registerForm.mobilePhone}`,
      };
      this.register(payload).then(() => {
        this.$router.push({ name: 'approve' });
      });
    },
  },
};
</script>

<style></style>
