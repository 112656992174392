/* eslint-disable no-unused-expressions */
import { required, email, maxLength, helpers, minLength, sameAs } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const registerValidations = {
  validations: {
    registerForm: {
      name: {
        required,
        maxLength: maxLength(30),
      },
      eMail: {
        email,
      },
      mobilePhone: {
        required,
        phoneCheck,
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(16),
      },
      kvkkApproved: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    nameErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.registerForm.name;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen adınızı soyadınızı giriniz.');
      return errors;
    },
    mailErrors() {
      const errors = [];
      const { $dirty, email } = this.$v.registerForm.eMail;
      if (!$dirty) return errors;
      !email && errors.push('Lütfen geçerli bir e-posta adresinizi giriniz.');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      const { $dirty, required, phoneCheck } = this.$v.registerForm.mobilePhone;
      if (!$dirty) return errors;
      !required && errors.push('Bu bilgi gereklidir.');
      !phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const { $dirty, required, minLength, maxLength, $params } = this.$v.registerForm.password;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen bir şifre giriniz.');
      !minLength && errors.push(`Şifre minimum ${$params.minLength.min} karakter olmalıdır.`);
      !maxLength && errors.push(`Şifre maksimum ${$params.maxLength.max} karakter olabilir.`);
      return errors;
    },
    kvkkApprovedErrors() {
      const errors = [];
      const { $dirty, required, sameAs } = this.$v.registerForm.kvkkApproved;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen aydınlatma metnini onaylayın.');
      !sameAs && errors.push('aydınlatma metnini onaylamanız gerekiyor.');
      return errors;
    },
  },
};

export default registerValidations;
